const columnConfig = {
  series: [
    {
      name: "Citas",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    background: "#fff",
    borderRadius: 10,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter(val) {
      return `${val}`;
    },
    style: {
      fontSize: "14px",
      colors: ["#ffffff"],
    },
  },
  xaxis: {
    categories: [],
    position: "bottom",
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      fill: {
        type: "gradient",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
    },
    tooltip: {
      enabled: true,
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
      formatter(val) {
        return `${val}`;
      },
    },
  },
  title: {
    floating: true,
    offsetY: 330,
    align: "center",
    style: {
      color: "#444",
    },
  },
};

export default columnConfig;
