<template>
  <div>
    <div class="titles my-5" v-text="`Equipo ${dinamicTitle}`"></div>
    <div
      class="mb-4 metrics__graph--shadow"
      id="columnChartRef"
      ref="columnChartRef"></div>
    <template v-if="this.stackedColumnsChartOptions.series.length == 1">
      <h4 class="titles ms-3">
        {{ this.stackedColumnsChartOptions.series[0].name }}
      </h4>
    </template>
    <div
      class="metrics__graph--shadow"
      id="stackedColumnChartRef"
      ref="stackedColumnChartRef"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import columnConfig from "@/constants/metrics/chartColumnConfig";
import stackedColumnConfig from "@/constants/metrics/chartStackedColumnConfig";
import ApexCharts from "apexcharts";

export default {
  name: "Graphics",
  props: {
    filterData: {
      type: Object,
      default() {
        return {};
      },
    },
    selector: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columnsChartOptions: columnConfig,
      stackedColumnsChartOptions: stackedColumnConfig,
    };
  },
  mounted() {
    // TODO: Here the only call must be the call to the api
    this.chartColumnGenerator(
      true,
      this.$refs.columnChartRef,
      this.columnsChartOptions,
      "chartColumns"
    );
    // TODO: Inside the method when have the info from the api will call the chartColumnGenerator
    this.chartColumnGenerator(
      true,
      this.$refs.stackedColumnChartRef,
      this.stackedColumnsChartOptions,
      "chartStackedColumns"
    );
  },
  methods: {
    // TODO: Add function for simulate the call to the api.
    chartColumnGenerator(onMounted = false, charRef, options, chartType) {
      if (!onMounted) this[chartType].destroy();
      this[chartType] = new ApexCharts(charRef, options);
      this[chartType].render();
    },
    updateChartData() {
      if (this.filterData) {
        let day = 1;
        this.columnsChartOptions.series[0].data = [];
        this.columnsChartOptions.xaxis.categories = [];
        this.stackedColumnsChartOptions.series = [];
        this.stackedColumnsChartOptions.xaxis.categories = [];
        if (
          this.getUserType !== "cacit" &&
          (this.getUserType === "medic" || this.selector === "medic")
        ) {
          this.filterData.medics.forEach((item) => {
            this.columnsChartOptions.series[0].data.push(item.data);
            this.columnsChartOptions.xaxis.categories.push(item.name);
          });
        } else {
          this.filterData.cacits.forEach((item) => {
            this.columnsChartOptions.series[0].data.push(item.data);
            this.columnsChartOptions.xaxis.categories.push(item.name);
          });
        }
        this.chartColumnGenerator(
          false,
          this.$refs.columnChartRef,
          this.columnsChartOptions,
          "chartColumns"
        );
        this.filterData.days.forEach((item) => {
          this.stackedColumnsChartOptions.series.push({
            name: item.name,
            data: item.data,
          });
        });
        this.filterData.days[0].data.forEach(() => {
          this.stackedColumnsChartOptions.xaxis.categories.push(day);
          day += 1;
        });
        this.chartColumnGenerator(
          false,
          this.$refs.stackedColumnChartRef,
          this.stackedColumnsChartOptions,
          "chartStackedColumns"
        );
      }
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
    dinamicTitle() {
      return this.getUserType !== "cacit" &&
        (this.getUserType === "medic" || this.selector === "medic")
        ? "Medico"
        : "Cacit";
    },
  },
  watch: {
    filterData: {
      handler() {
        this.updateChartData();
      },
    },
  },
};
</script>
